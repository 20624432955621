<template>
  <div class="soft-container">
    <div class="banner-box">
      <video autoplay loop muted="true" class="video">
        <source src="../../assets/video/welcome.mp4" type="video/mp4"/>
      </video>
      <head-navigation/>
      <div class="play-btn-box">
        <svg-icon icon-class="play" @click="handleWatch"/>
      </div>
    </div>
    <div class="layer" v-if="layerVisible">
      <div class="container-layer">
        <span @click="handleClose">×</span>
        <video controls autoplay muted="true">
          <source src="../../assets/video/welcome.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
    <div class="product-box">
      <div class="title-box">
        <div class="title">
          <svg-icon icon-class="ic_title1"></svg-icon>
          <span>产品架构</span>
        </div>
        <p>思腾合力AI开放平台由物理层，数据层，服务层三大部分组成</p>
      </div>
      <ul>
        <li>
          <span><b>物理层 </b>基于思腾合力算力基础设施的强大支撑</span>
        </li>
        <li>
          <span><b>数据层 </b>支持视频流数据、多模态数据</span>
        </li>
        <li>
          <span>
            <b>服务层 </b>
            可通过多种交互方式进行深度学习和机器学习任务提交，支持接管多种类型存储，面向
            不同行业应用提供丰富的存储文件系统；提供丰富的AI模型和算法库，使开发者能够快速使用和
            集成先进的AI技术
          </span>
        </li>
      </ul>
    </div>
    <div class="management-platForm">
      <div class="title-box">
        <div class="title">
          <svg-icon icon-class="ic_title1"></svg-icon>
          <span>管理平台</span>
        </div>
      </div>
      <div class="content">
        <template v-for="item in platFormDatasource" :key="item.title">
          <div class="item">
            <div class="img-box">
              <img :src="item.img" alt="" />
            </div>
            <h4>{{item.title}}</h4>
            <p>{{item.desc}}</p>
          </div>
        </template>
      </div>
    </div>
    <div class="algorithm-management">
      <div class="title-box">
        <div class="title">
          <svg-icon icon-class="ic_title"/>
          <span>算法管理</span>
        </div>
      </div>
      <div class="content">
        <div class="img-left">
          <img src="../../assets/img/img_1.png" alt=""/>
          <div class="item-content">
            <h4>提供丰富的算法和模型库</h4>
            <p>
              思腾自研图像算法模型，并使用多种优化方法计算，在图像分割、目标检测等
              场景使用效果取得巨大提升<br/>
              平台储备多种场景的模型库，其中涉及：智能制造、智慧园区、智慧能源等多
              种应用场景
            </p>
          </div>
        </div>
        <div class="img-right">
          <div class="top-img">
            <img src="../../assets/img/img_3.png" alt="" />
            <div class="item-content">
              <h4>支持数据管理和在线标注</h4>
              <p>
                内置了基于web的图片数据标注工具，支持矩形圈选图片特征区域，帮助用户更加准确地标注出所需的数据特征。
              </p>
            </div>
          </div>
          <div class="bottom-img">
            <img src="../../assets/img/img_2.png" alt="" />
            <div class="item-content">
              <h4>通过版本管理跟踪模型的演化历史</h4>
              <p>
                每个版本记录了模型的修改、更新和改进，以及相关的训练数据和参数设置，方便地回溯和复现特定版本的模型，有助
                于确保模型的安全性和稳定性。每个版本都可以进行备份和存档，以防止意外数据丢失或模型损坏。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="yw">
      <div class="content">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div class="box">
          <h1>运维部分</h1>
          <p>
            团队成员都是经验丰富的工程师，具备广泛的技术知
            识和实践经验。熟悉各种操作系统和网络架构，能够
            迅速解决系统和网络故障，并进行系统优化和性能调
            整。为客户提供强大的技术支撑，同时我们拥有着完
            整且精细的客户交付和验收流程，能够快速地响应和
            处理客户的问题，通过大数据的收集和整理【数据大
            屏】，可以看到团队在项目实施中的系统稳定性故障
            修复时间，效率提升所带来的成果
          </p>
        </div>
      </div>
      <div class="img-box">
        <img src="../../assets/img/img_yunwei.png" alt="">
      </div>
    </div>
    <div class="empty-box"></div>
    <div class="bottom-banner">
      <img src="../../assets/img/img_banner_bottom.png" alt="">
    </div>
    <Footer/>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import HeadNavigation from '@/components/HeadNavigation'
import Footer from '@/components/footer/Footer'
export default defineComponent({
  components: {
    HeadNavigation,
    Footer
  },
  setup () {
    const layerVisible = ref(false)
    const handleWatch = () => {
      layerVisible.value = true
    }
    const handleClose = () => {
      layerVisible.value = false
    }

    const setVideoHeight = () => {
      const clientHeigth = document.querySelector('.banner-box').clientHeight
      document.querySelector('.video').height = clientHeigth
    }

    const platFormDatasource = [
      {
        img: require('@/assets/img/ic_ai.png'),
        title: 'Ai训练中心',
        desc: '提供一站式深度学习、机器学习任务提交功能，可选系统内置镜像' +
              '或者用户自定义镜像文件，提供对CPU、存储、内存、加速计算卡' +
              '的使用率实时监控，支持容器环境无卡模式运行，用户可通过多种' +
              '交互方式进行任务提交。'
      },
      {
        img: require('@/assets/img/yunshuju.png'),
        title: '存储中心',
        desc: '支持接管多种存储类型，支持nfs、glusterfs、ceph、lustre等主流' +
              '存储文件系统，支持纳管对象存储系统，面向不同行业应用提供丰' +
              '富的存储文件系统，自主研发缓存机制，提高存储系统的读写性能。'
      },
      {
        img: require('@/assets/img/ic_jifei.png'),
        title: '计费中心',
        desc: '提供完整的计费、计时功能，帮助用户高效精准地使用算力资源，' +
              '提升工作效率，节省人力成本；费用报表长期存储，随时可查。'
      },
      {
        img: require('@/assets/img/ic_yunwei.png'),
        title: '运维中心',
        desc: '提供多种计算卡的纳管，包含nvidia、天数智芯、寒武纪、昇腾' +
              '等，支持在线升级和上下架服务器，提供在线license及离线' +
              'license管理功能 '
      },
      {
        img: require('@/assets/img/ic_yonghu.png'),
        title: '用户中心',
        desc: '提供完整的用户管理功能，支持多级用户管理，支持对用户分级；' +
              '支持在线提交服务工单'
      },
      {
        img: require('@/assets/img/ic_zichan.png'),
        title: '资产管理中心',
        desc: '在线查看机房的设备状态、机柜状态，实时查看机房设备的功耗' +
              '状况，提供设备故障预测、分类管理告警事件功能'
      }
    ]

    onMounted(() => {
      setVideoHeight()
    })
    return {
      layerVisible,
      handleWatch,
      handleClose,
      platFormDatasource
    }
  }
})
</script>
<style lang="scss" scoped>
  .soft-container {
    background-color: #000000;
    .title-box {
      overflow: hidden;
      .title {
        text-align: center;
        svg {
          width: 20px;
          height: 20px;
        }
        span {
          font-size: 28px;
          color: #ffffff;
          font-weight: 700;
          vertical-align: middle;
          margin-left: 16px;
        }
      }
      p {
        margin-top: 30px;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
      }
    }
    .banner-box {
      height: 100%;
      position: relative;
      .video {
        width: 100%;
        object-fit: cover;
        display: block;
      }
      .play-btn-box {
        width: 260px;
        height: 260px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: rgba(0,0,0,0.20);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 60px;
          height: 54px;
          cursor: pointer;
        }
      }
    }
    .layer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.7);
      z-index: 20;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 95px 0;
      .container-layer {
        position: relative;
        width: 1200px;
        height: 100%;
        text-align: center;
        video {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      span {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #ffffff;
        font-size: 20px;
        cursor: pointer;
        z-index: 22;
        transition: all linear 0.5s;

        &:hover {
          transform: rotate(360deg);
        }
      }
    }
    .product-box {
      background-size: 101% 101%;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          color: #ffffff;
          font-size: 18px;
          span {
            b {
              font-size: 24px;
              &::before {
                display: inline-block;
                content: '';
                width: 10px;
                height: 10px;
                background-color: #ffffff;
                border: 2px solid #183FFF;
                border-radius: 50%;
                box-sizing: border-box;
                margin-right: 14px;
                margin-bottom: 3px;
                filter: blur(2px);
                animation: blurAnimate 2s linear infinite;
              }
            }
          }
        }
      }
    }
    .management-platForm {
      height: 1080px;
      background-color: #000000;
      display: flex;
      flex-direction: column;
      .title-box {
        .title {
          margin-top: 100px;
        }
      }
      .content {
        flex: 1;
        margin: 104px auto 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        .item {
          width: calc(33.3% - 15px);
          height: 313px;
          background-color: #272727;
          box-shadow: 0 2px 6px 0 rgba(0,0,0,0.50);
          margin-top: 22px;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
          &:nth-child(2) {
            margin: 22px 22px 0 22px;
          }
          &:nth-child(5) {
            margin: 22px 22px 0 22px;
          }
          .img-box {
            margin-top: 70px;
            img {
              width: 46px;
              height: 46px;
            }
          }
          h4 {
            margin-top: 40px;
            color: #ffffff;
            font-size: 20px;
            font-weight: 500;
          }
          p {
            margin-top: 28px;
            font-size: 14px;
            color: #D0CECE;
            padding: 0 23px;
          }
        }
      }
    }
    .algorithm-management {
      height: 1235px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      .title-box {
        .title {
          margin-top: 121px;
          span {
            color: #000000;
          }
        }
      }
      .content {
        margin: 80px auto 0 auto;
        flex: 1;
        display: flex;
        .img-left {
          width: 40%;
          height: 894px;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            transition: all linear .5s;
            &:hover {
              transform: scale(1.5);
            }
          }
          .item-content {
            position: absolute;
            top: 686px;
            left: 40px;
            right: 42px;
            color: #ffffff;
            h4 {
              font-size: 20px;
              font-weight: 700;
            }
            p {
              font-size: 14px;
              margin-top: 36px;
            }
          }
        }
        .img-right {
          flex: 1;
          height: 894px;
          margin-left: 22px;
          .top-img {
            height: 436px;
            overflow: hidden;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              transition: all linear .5s;
              &:hover {
                transform: scale(1.5);
              }
            }
            .item-content {
              position: absolute;
              top: 275px;
              left: 40px;
              right: 78px;
              color: #ffffff;
              h4 {
                font-size: 20px;
                font-weight: 700;
              }
              p {
                font-size: 14px;
                margin-top: 28px;
              }
            }
          }
          .bottom-img {
            height: 436px;
            margin-top: 22px;
            overflow: hidden;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              transition: all linear .5s;
              &:hover {
                transform: scale(1.5);
              }
            }
            .item-content {
              position: absolute;
              top: 228px;
              left: 40px;
              right: 52px;
              color: #ffffff;
              h4 {
                font-size: 20px;
                font-weight: 700;
              }
              p {
                font-size: 14px;
                margin-top: 36px;
              }
            }
          }
        }
      }
    }
    .yw {
      height: 738px;
      display: flex;
      .content {
        width: 43%;
        height: 100%;
        background-color: #1E232C;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 4px;
          height: 4px;
          background-color: rgba(216,216,216,0.00);
          opacity: .5;
          border-radius: 50%;
          box-shadow: 0 0 0 4px rgba($color: #ffffff, $alpha: 0.1)
          0 0 0 8px rgba($color: #ffffff, $alpha: 0.1)
          0 0 0 20px rgba($color: #ffffff, $alpha: 1);
          animation: animate 2s linear infinite;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            width: 200px;
            height: 2px;
            background: linear-gradient(90deg, #ffffff, transparent);
            transform: translateY(-50%);
            opacity: .5;
          }
          &:nth-child(1) {
            top: 0;
            right: 0;
            left: initial;
            animation-delay: 0.2s;
            animation-duration: 2s;
          }
          &:nth-child(2) {
            top: 0;
            right: 0;
            left: initial;
            animation-delay: 0.2s;
            animation-duration: 2.2s;
          }
          &:nth-child(3) {
            top: 80;
            right: 0;
            left: initial;
            animation-delay: 0.4s;
            animation-duration: 2s;
          }
          &:nth-child(4) {
            top: 80;
            right: 0;
            left: initial;
            animation-delay: 0.4s;
            animation-duration: 2.2s;
          }
        }
        .box {
          box-sizing: border-box;
          h1 {
            color: #ffffff;
            font-size: 32px;
            font-weight: 700;
          }
          p {
            font-size: 16px;
            color: #FFFFFF;
            margin-top: 64px;
            width: 375px;
          }
        }
      }
      .img-box {
        width: 57%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .empty-box {
      height: 80px;
      background-color: #ffffff;
    }
    .bottom-banner {
      height: 654px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @media screen and (min-width: 1366px){
    .banner-box {
      .navigation {
        :deep(.center) {
          width: 91%;
        }
      }
    }
    .product-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 768px;
      background: url(../../assets/img/img_chanpinjiagou_1366.png) no-repeat center;
      .title-box {
        .title {
          margin-top: 86px;
        }
      }
      ul {
        margin: 10% 0 0 40%!important;
        li {
          width: 700px;
          &:not(:first-child) {
            margin-top: 25px;
          }
        }
      }
    }
    .management-platForm, .algorithm-management {
      .content {
        width: 91%;
      }
    }
  }
  @media screen and (min-width: 1920px){
    .banner-box {
      .navigation {
        :deep(.center) {
          width: 73%;
        }
      }
    }
    .product-box {
      display: block;
      height: 1080px;
      background: url(../../assets/img/img_chanpinjiagou.png) no-repeat center;
      .title-box {
        .title {
          margin-top: 120px;
        }
      }
      ul {
        margin: 286px 0 0 892px!important;
        li {
          width: 755px;
        }
      }
    }
    .management-platForm, .algorithm-management {
      .content {
        width: 73%;
      }
    }
  }

  @keyframes blurAnimate {
    from {
      filter: blur(2px);
    }
    to {
      filter: blur(10px);
    }
  }

  @keyframes animate {
    0% {
      transform: rotate(315deg) translateX(0);
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: rotate(315deg) translateX(-1000px);
    }
  }
</style>
